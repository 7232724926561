@font-face {
  font-family: 'QuietSans';
  src: url('../fonts/QuietSans-Regular.otf');
}

@font-face {
  font-family: 'QuietSans';
  src: url('../fonts/QuietSans-Bold.otf');
  font-weight: bold;
}

@font-face {
  font-family: 'QuietSans';
  src: url('../fonts/QuietSans-ExtraBold.otf');
  font-weight: 800;
}

@font-face {
  font-family: 'QuietSans';
  src: url('../fonts/QuietSans-Light.otf');
  font-weight: 300;
}

@font-face {
  font-family: 'QuietSans';
  src: url('../fonts/QuietSans-SemiBold.otf');
  font-weight: 600;
}