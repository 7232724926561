:root {
  --light: #FBFAFF;
  --light-hover: #C9C5ED;
  --blue: #331FF6;
  --blue-hover: rgba(22, 7, 167, 1);;
  --light-hover-ten: rgba(251, 250, 255, 0.1);
  --blue-hover-ten: rgba(51, 31, 246, 0.1);
  --dark: rgba(16, 11, 65, 1);
  --mid: rgba(58, 54, 99, 1);
  --turquise: rgba(81, 199, 255, 1);
  --lavender: rgba(97, 111, 247, 1);
  --light-bg: rgba(243, 242, 253, 1);
}

.main-banner {
  background: url("../images/bg-img-hero-min.png"), var(--blue-hover);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.main-banner .container {
  display: flex;
  justify-content: center;
}

.main-banner .container .main-banner-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  max-width: unset;
}

.main-banner .container .main-banner-content p {
  font-size: 32px;
  font-weight: 400;
  line-height: 45px;
  letter-spacing: 0;
  text-align: center;
  color: var(--light-hover);
}

.main-banner .container .event-countdown #timer {
  text-align: center;
  display: flex;
  justify-content: center;
}

h1 {
  font-size: 80px;
  font-weight: 700;
  line-height: 96px;
  letter-spacing: 0;
  text-align: center;
  color: var(--light);
}

.main-banner .container .main-banner-content ul li {
  font-size: 24px;
  font-weight: 400;
  line-height: 34px;
  text-align: center;
  color: var(--light);
}

.btn-gpw {
  padding: 16px 24px 16px 24px;
  border-radius: 24px;
  gap: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 120%;
  text-align: center;
  transition: all .2s ease-in-out;
}

.btn-gpw-small {
  font-size: 16px;
  border-radius: 16px;
}

.btn-gpw-main {
  background: var(--light);
  border: 1px solid var(--light);
  box-shadow: 2px 2px 10px rgba(16, 11, 65, 0.15);
  color: var(--blue);
}

.btn-gpw-main:hover {
  background: var(--light-hover);
  border: 1px solid var(--light-hover);
  color: var(--blue);
}

.btn-gpw-sec {
  background: transparent;
  border: 1px solid var(--light);
  color: var(--light);
}

.btn-gpw-sec:hover {
  background: var(--light-hover-ten);
  color: var(--light);
}

.button-box {
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  flex-direction: row;
  gap: 1rem;
}

.event-countdown #timer div {
  font-size: 64px;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  color: #FBFAFF50
}

.event-countdown #timer div p {
  font-size: 32px;
  font-weight: 400;
  line-height: 1;
  letter-spacing: 0.05em;
  text-align: center;
}

.event-countdown #timer div#days,
.event-countdown #timer div#hours,
.event-countdown #timer div#min{
  color: var(--light);
}

.logo-menu {
  max-width: 170px;
  fill: white;
}

.elkevent-nav {

}

.elkevent-nav.is-sticky {
  background-color: rgba(251, 250, 255, 0.8) !important;
}

.elkevent-nav.is-sticky .btn-gpw-main,
.section-title-wtih-btn .btn-gpw-main {
  background: var(--blue);
  border: 1px solid var(--blue);
  box-shadow: 2px 2px 10px rgba(16, 11, 65, 0.15);
  color: var(--light);
}

.elkevent-nav.is-sticky .btn-gpw-main:hover,
.elkevent-nav.is-sticky .btn-gpw-main:hover,
.section-title-wtih-btn .btn-gpw-main:hover {
  background: var(--blue-hover);
  border: 1px solid var(--blue-hover);
  color: var(--light);
}

.elkevent-nav.is-sticky .btn-gpw-sec,
.schedule-area .btn-gpw-sec,
.blog-area .btn-gpw-sec,
.section-title-wtih-btn .btn-gpw-sec {
  background: transparent;
  border: 1px solid var(--blue);
  color: var(--blue);
}

.elkevent-nav.is-sticky .btn-gpw-sec:hover,
.schedule-area .btn-gpw-sec:hover,
.blog-area .btn-gpw-sec:hover,
.section-title-wtih-btn .btn-gpw-sec:hover {
  background: var(--blue-hover-ten);
  color: var(--blue);
}

.elkevent-nav.is-sticky .logo-menu {
  fill: rgba(0, 0, 0, 1);
}

@media (max-width: 768px) {
  .logo-menu {
    max-width: 135px;
  }

  .navbar-brand {
    margin-right: 0;
  }

  .navbar .container {
    display: flex;
    flex-direction: column;
  }

  .navbar .navbar-nav {
    margin-top: 0.5rem;
    display: flex;
    flex-direction: row;
  }

  .main-banner .container .main-banner-content p {
    font-size: 16px;
    text-align: left;
    margin-top: 2rem;
    line-height: 100%;
    min-height: 32px;
  }

  .main-banner h1 {
    font-size: 48px;
    line-height: 1.2;
  }
  h1 {
    font-size: 48px;
    text-align: left;
  }

  .main-banner .container .main-banner-content ul {
    display: flex;
    flex-direction: column;
  }

  .main-banner .container .main-banner-content ul li {
    font-size: 16px;
    line-height: 22.5px;
    text-align: left;
  }

  .main-banner .container .main-banner-content ul svg {
    width: 24px;
    height: 24px;
  }

  .button-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;
  }

  .event-countdown #timer div {
    font-size: 24px;
    line-height: 32px;
  }

  .event-countdown #timer div p {
    font-size: 16px;
    line-height: 22px;
  }

  .slideshow-banner .event-countdown #timer div {
    font-size: 40px;
  }

  .event-countdown #timer div p {
    font-size: 20px;
    text-transform: uppercase;
    margin-top: 0.4rem;
  }

  .slideshow-banner .event-countdown {
    margin: 2rem 0 !important;
  }
}

@media (max-width: 500px) {

  .slideshow-banner .event-countdown #timer div {
    font-size: 32px;
  }

  .event-countdown #timer div p {
    font-size: 16px;
  }
}

.about-content span {
  color: var(--blue);
}

h2 {
  color: var(--dark)
}

h6,p {
  color: var(--mid);
}

.about-area-three {
  background-color: var(--light);
}

.about-area-three .about-content ul li {
  color: var(--mid);
}

.about-area-three .about-image {
  padding-left: 2rem;
}

@media (max-width: 768px) {
  .about-area-three .about-image {
    margin-top: 50px;
    padding-left: 0;
  }
}

.activity-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 2rem
}

.activity-grid-span2 {
  grid-column: span 2;
}

.activity-grid-span3 {
  grid-column: span 3;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2rem
}

h3 {
  font-size: 24px;
  font-weight: 700;
  line-height: 34px;
  letter-spacing: 0em;
  text-align: left;
  color: var(--blue);
}

@media (max-width: 768px) {
  .activity-grid {
    grid-template-columns: 1fr;
  }

  .activity-grid-span2 {
    grid-column: 1;
  }

  .activity-grid-span3 {
    grid-column: 1;
    grid-template-columns: 1fr;
  }
}

.single-funfact p {
  font-size: 24px;
  font-weight: 600;
  line-height: 1.5;
  letter-spacing: 0.05em;
  text-align: left;
  color: var(--light-hover);
  text-transform: uppercase;
}

.single-funfact h3 {
  font-size: 48px;
  font-weight: 700;
  line-height: 1.5;
  text-align: left;
  color: var(--light) !important;
}

.single-funfact > div {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2rem;
}

.funfacts-area {
  background-image: url("../images/wide-bg.png");
  background-attachment: unset;
}

.funfacts-area::before {
  content: none;
}

@media (max-width: 768px) {
  .single-funfact p {
    font-size: 16px;
  }

  .single-funfact h3 {
    font-size: 32px;
  }

  .single-funfact > div {
    gap: 1rem;
  }
}

.section-title span {
  color: var(--blue);
}

.single-speakers-box {
  border-radius: 16px;
}

.single-speakers-box .speakers-image img {
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  height: 280px;
  width: 100%;
  object-fit: cover;
  filter: grayscale(1);
  transition: all .2s ease-in-out;
}

.single-speakers-box:hover .speakers-image img {
  filter: grayscale(0);
}

.single-speakers-box .speakers-content {
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
}

.single-speakers-box:hover .speakers-content {
  background: linear-gradient(180deg, #331FF6 0%, #9D05CE 100%);
}

.single-speakers-box .speakers-content::before {
  background-color: var(--blue);
}

.single-speakers-box .speakers-content .social li a {
  margin-right: 0;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.single-speakers-box .speakers-content .social li a::before {
  border-radius: 50%;
  border: 1px solid var(--blue);
}

.single-speakers-box .speakers-content h3,
.single-speakers-box .speakers-content h3 a{
  font-size: 24px;
  font-weight: 700;
  line-height: 1.3;
  text-align: center;
  color: var(--dark);
}

.single-speakers-box .speakers-content span {
  font-size: 12px;
  padding: 0 1rem;
  font-weight: 600;
  line-height: 1.5;
  text-align: center;
  color: var(--turquise);
  text-transform: none;
}

.speakers-area-two {
  background-color: var(--light-bg);
}

@media (min-width: 1200px) {
  .speakers-area-two .row .col-lg-4{
    width: 25%;
  }
}

.section-title-wtih-btn {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.section-title-wtih-btn .button-box {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  flex-wrap: nowrap;
  align-items: flex-end;
}

@media (max-width: 768px) {
  .section-title-wtih-btn {
    flex-direction: column;
  }

  .section-title-wtih-btn .button-box {
    flex-direction: column;
    gap: 1rem;
    align-items: stretch;
  }
}

.tab_content .tabs_item .accordion .accordion-item .accordion-title .schedule-info {
  padding: 35px 25px;
  padding-left: 0;
}

.tab_content .tabs_item .accordion .accordion-item .accordion-title .schedule-info h3 {
  font-size: 24px;
  font-weight: 700;
  line-height: 1.4;
  text-align: left;
  color: var(--dark);
  margin-bottom: 0;
  text-transform: none;
}

.tab_content .tabs_item .accordion .accordion-item .accordion-title .schedule-info p {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  color: var(--mid);
  opacity: 0.8;
}

.tab_content .tabs_item .accordion .accordion-item .accordion-title .schedule-info ul li {
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  text-align: left;
  color: var(--dark);
}

.tab_content .tabs_item .accordion .accordion-item .accordion-title .schedule-info ul li svg {
  color: var(--lavender);
  margin-right: 0.4rem;
}

.tab_content .tabs_item .accordion .accordion-item {
  display: flex;
  flex-direction: row;
  box-shadow: 4px 4px 15px 0 rgba(22, 7, 167, 0.2);
  background: rgba(255, 255, 255, 1);
  border-radius: 16px;
  gap: 24px;
  border: none;
  padding: 0 !important;
}

.tab_content .tabs_item .accordion .accordion-item .info-ctn {
  background-color: var(--light-bg);
  padding: 35px 25px;
  border-bottom-left-radius: 16px;
  border-top-left-radius: 16px;
}

.tab_content .tabs_item .accordion .accordion-item .info-ctn p {
  font-size: 24px;
  font-weight: 700;
  line-height: 1.4;
  text-align: left;
  color: var(--dark);
  white-space: nowrap;
  margin: 0.5rem 0;
}

.tab_content .tabs_item .accordion .accordion-item .info-ctn .time {
  font-size: 16px;
  font-weight: 600;
  line-height: 1.4px;
  text-align: left;
  color: var(--mid);
}

.tab_content .tabs_item .accordion .accordion-item .info-ctn .time svg {
  color: var(--lavender);
  margin-right: 0.4rem;
}


.tab_content .tabs_item .accordion .accordion-item .info-ctn .type {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
}

.tab_content .tabs_item .accordion .accordion-item .info-ctn .type span {
  font-size: 14px;
  font-weight: 600;
  line-height: 1.4;
  letter-spacing: 0.05em;
  text-align: left;
  text-transform: uppercase;
  opacity: 0.5;
  color: var(--mid);
}

.tab_content .tabs_item .accordion .accordion-item .info-ctn .type > div {
  width: 32px;
  height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: var(--blue);
}

.tab_content .tabs_item .accordion .accordion-item .info-ctn .type svg {
  color: white;
}

.tab {
  width: 100%;
  box-shadow: none;
  background-color: unset;
}

.tab_content {
  margin: auto;
  max-width: 930px;
  padding: 0;
  background-color: unset;
}

.schedule-area {
  background-color: var(--light);
}

.schedule-area.bg-image {
  background-image: unset;
}

.schedule-area .btn-box a {
  width: fit-content;
  margin: auto;
}

@media (max-width: 768px) {
  .tab_content .tabs_item .accordion .accordion-item {
    flex-direction: column;
  }

  .tab_content .tabs_item .accordion .accordion-item .accordion-title .schedule-info {
    padding-left: 25px;
    padding-top: 0;
  }

  .tab_content .tabs_item .accordion .accordion-item .info-ctn {
    padding: 15px 25px;
    border-bottom-left-radius: 0;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
  }

  .tab_content .tabs_item .accordion .accordion-item .accordion-title .schedule-info ul li {
    display: flex;
  }

  .tab_content .tabs_item .accordion .accordion-item .accordion-title .schedule-info ul li svg {
    width: 24px;
    min-width: 24px;
  }
}

.partner-area {
  background-color: var(--light);
  padding-top: 0;
}

.partner-area .partner-title h3,
.partner-area .partner-title.gold-sponsor h3 {
  font-size: 32px;
  font-weight: 600;
  line-height: 1.4;
  text-align: center;
  color: var(--blue);
  text-transform: none;
}

.partner-area .logo-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  row-gap: 2rem;
  column-gap: 2rem;
  margin-bottom: 4rem;
}

.partner-area .logo-gird-flex {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.partner-area .logo-grid > div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.partner-area .logo-gird-flex > div {
  max-width: 250px;
}

.partner-area .logo-gird-flex.kghm-logo > div {
  max-width: 300px;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.partner-area .logo-grid.big {
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.partner-area .logo-grid .partner-item a img:first-child {
  filter: grayscale(1);
}

@media (max-width: 940px) {
  .partner-area .logo-grid {
    grid-template-columns: 1fr 1fr 1fr;
  }

  .partner-area .logo-grid.big {
    grid-template-columns: 1fr 1fr;
  }
}

@media (max-width: 768px) {
  .partner-area {
    padding-top: 2rem;
  }

  .partner-area .logo-grid {
    grid-template-columns: 1fr 1fr;
    row-gap: 2rem;
  }

  .partner-area .logo-grid.big {
    grid-template-columns: 1fr;
  }

  .partner-area .logo-gird-flex {
    flex-direction: column;
  }
}

.slideshow-banner .event-countdown #days {
  margin-left: 35px;
}

.cta-area {
  padding-top: 120px;
  padding-bottom: 120px;
  background-image: url('../images/gpw/mowca-motyw.png');
  background-position: top right;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: unset;
}

.cta-area .main-ctn,
.cta-area .main-ctn > div {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.cta-area h2 {
  font-size: 48px;
  font-weight: 700;
  line-height: 1.5;
  text-align: center;
  color: var(--light);
}

.cta-area span {
  font-size: 24px;
  font-weight: 600;
  line-height: 1.5;
  color: var(--light);
  opacity: 0.7;
}

.blog-area .info-ctn {
  display: flex;
  flex-direction: row;
  box-shadow: 4px 4px 15px 0 rgba(22, 7, 167, 0.2);
  border-radius: 24px;
  background: rgba(255, 255, 255, 1);
  margin-bottom: 1rem;
}

.blog-area .info-ctn .icon-ctn {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px;
  background-color: var(--light-bg);
  border-top-left-radius: 24px;
  border-bottom-left-radius: 24px;
}

.blog-area .info-ctn .icon-ctn svg {
  color: var(--lavender);
}

.blog-area .content-ctn {
  padding: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.blog-area .content-ctn h3 {
  font-size: 24px;
  font-weight: 700;
  line-height: 1.4;
  text-align: left;
  color: var(--dark);
  margin-bottom: 0;
}

@media (max-width: 500px) {
  .blog-area .content-ctn h3.email {
    font-size: 12px !important;
  }
}

.blog-area .content-ctn p {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0;
  text-align: left;
  color: var(--mid);
  opacity: 0.8;
}

.blog-area .content-ctn a {
  height: fit-content;
  width: fit-content;
  padding: 12px 16px;
}

.google-map-code {
  position: relative;
  padding-bottom: 75%;
  height: 0;
  overflow: hidden;
  border-radius: 24px;
  margin-left: 4rem;
}

.google-map-code .iframe {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100% !important;
  height: 400px !important;
  border-radius: 8px;
  border: 0;
}

@media (max-width: 1030px) {
  .cta-area {
    background-position: top center;
  }

  .google-map-code {
    margin-left: 0;
  }

  .blog-area .row {
    flex-direction: column;
  }

  .blog-area .row .col-md-6 {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .blog-area .content-ctn {
    flex-direction: column;
    align-items: flex-start;
    gap: 0.5rem
  }

  .blog-area .content-ctn a {
    width: 100%;
  }
}

.footer-area {
  background-color: var(--dark);
  padding-bottom: 4rem;
}

.footer-ctn {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
}

.footer-ctn .icon-text-ctn {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 0.5rem;
  color: var(--light);
  margin-top: 1rem;
}

.footer-ctn .icon-text-ctn p {
  font-size: 18px;
  font-weight: 700;
  line-height: 1.4;
  text-align: left;
  color: var(--light);
}

.footer-ctn h3 {
  margin-bottom: 1rem;
}

.footer-ctn .icon-text-ctn span {
  font-size: 14px;
  font-weight: 600;
  line-height: 1.4;
  text-align: left;
  color: var(--light);
}

.single-footer-widget .social-links li a {
  border: none !important;
}

.single-footer-widget .social-links li a:hover,
.single-footer-widget .social-links li a:focus {
  background-color: var(--blue);
  border: none;
}

.single-footer-widget .social-links li a i {
  font-size: 22px;
}

.single-footer-widget p {
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0;
  text-align: left;
  color: var(--light-hover);
}

.single-footer-widget .xd {
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: 0;
  text-align: left;
  color: var(--light);
}

.single-footer-widget .logo-menu {
  margin-bottom: 1rem;
}

.footer-area .participate p {
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: 0;
  text-align: left;
}

@media (max-width: 968px) {
  .footer-ctn {
    display: flex;
    flex-direction: column;
    gap: 2rem;
  }

  .footer-ctn > div {
    width: 100%;
  }
}

.main-banner video {
  object-fit: cover;
  width: 100vw;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.main-banner::before {
  content: none;
}

.theme1 {
  position: absolute;
  top: 150px;
  right: 20px;
  z-index: 0;
}

.theme2 {
  position: absolute;
  bottom: 150px;
  left: 0;
  z-index: 0;
}

@media (max-width: 1200px) {
  .theme1,
  .theme2{
    display: none;
  }
}

.about-area-three.bg-image {
  background-image: unset;
}

.preloader {
  background-color: var(--dark);
}

.preloader .loader,
.preloader .loader:before,
.preloader .loader:after {
  border-top-color: var(--blue);
}

.back-to-top2 {
  position: fixed;
  right: 1.5%;
  bottom: 15px;
  cursor: pointer;
  z-index: 10;
  cursor: pointer;
}

@media (max-width: 768px) {
  .back-to-top2 {
    display: none;
  }
}

.partner-area .partner-item a img:last-child {
  -webkit-transition: 0s;
  transition: 0s;
}

.tmp-fun-ctn h2 {
  font-size: 48px;
  font-weight: 700;
  line-height: 67px;
  color: var(--light);
  text-align: center;
}

.tmp-fun-ctn p {
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  text-align: center;
  color: var(--light);
  opacity: 0.7;
  margin-top: 1rem;
}

.tab_content .tabs_item .accordion .tmp-agenda-ctn {
  display: flex;
  flex-direction: column;
  text-align: center;
  border-radius: 24px;
  padding: 0;
  background-color: var(--light-bg) !important;
  gap: 1rem;
  overflow: scroll;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.tab_content .tabs_item .accordion .tmp-agenda-ctn::-webkit-scrollbar {
  display: none;
}

.tmp-agenda-ctn h3 {
  font-size: 32px;
  font-weight: 700;
  line-height: 100%;
  text-align: left;
  color: var(--dark);
  margin: 2rem 2rem 0.8rem;
}

.tmp-agenda-ctn p {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
  color: var(--mid);
  opacity: 0.7;
}

.banner-extra-text {
  margin-top: 2rem;
  font-size: 24px;
  font-weight: 400;
  line-height: 34px;
  text-align: center;
  color: var(--light);
}

@media (max-width: 700px) {
  .banner-extra-text {
    font-size: 20px;
  }
}

.gpwLink {
  color: white;
  text-decoration: underline;
  font-weight: bold;
  margin-top: 0.4rem;
}

.changin-text #container {
  text-align: center;
}

.changin-text #text {
  display: inline-block;
  vertical-align: middle;
  color: orange;
  letter-spacing: 2px;
}

.changin-text #cursor {
  display: inline-block;
  vertical-align: middle;
  width: 3px;
  height: 50px;
  background-color: orange;
  animation: blink .75s step-end infinite;
}

@keyframes blink {
  from, to {
    background-color: transparent
  }
  50% {
    background-color: orange;
  }
}

.scene-grid {
  display: flex;
  flex-direction: row;
  background-color: white;
  border-bottom-left-radius: 24px;
  width: fit-content;
}

.scene-grid > div {
  padding: 0.8rem 2rem;
  font-weight: 700;
  color: var(--blue);
  text-transform: uppercase;
  cursor: pointer;
  white-space: nowrap;
}

.scene-grid > div.selected {
  background-color: var(--blue);
  color: white;
}

.scene-grid > div.selected-2 {
  background-color: var(--turquise);
  color: white;
}

.scene-grid > div.selected-3 {
  background-color: var(--lavender);
  color: white;
}

.scene-grid > div.first {
  border-bottom-left-radius: 24px;
}

@media (min-width: 992px) {
  .col-lg-3 {
    flex: 0 0 auto;
    width: 33%;
  }
}

.single-funfact {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.funfacts-area .row {
  justify-content: center;
}

.tmp-agenda-ctn2 {
  display: flex;
  padding: 40px 1rem !important;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 24px;
  background: var(--light-bg, #F3F2FD);
}